#tutorialBlock {
  
}

#tutorialBlockIntro {
  
}

#tutorialBlockModel {
  
}

#tutorialWelcomeText {
  
}

#tutorialWalletButton {
	
}

#tutorialBlockPicture {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  height: 15vh;
}