.ReactCollapse--collapse {
    transition: height 500ms;
}
.chevron{
    transition: 0.5s ease-in-out;
}

.rotateUp {
    transform: rotate(180deg);

}

.collapse-container {
    width: 100%;
}

