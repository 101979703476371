#informationsTitle {
	font-family: Silvana;
	font-weight: 300;
	font-size: 60px;
	color: var(--main-link-color);
	margin-top: 0;
	margin-bottom: 40px;
	line-height: 1em;
}

.container__lists__info {
	list-style: none;
	width: 100%;
	padding: 0;
	margin: 0;
}
.container__info {
	padding-top: 20px;
	list-style: none;
	display: flex;
	padding-bottom: 20px;
	border-bottom: 0.5px solid var(--footer-separator-color);
}



.container__svg__info {
	height: 44px;
	width: 44px;
	margin-right: 12px;
	background: var(--background-svg);
	border-radius: 50%;
	position: relative;
}

.container__svg__info span svg {
	position: absolute;
	left: 50%;
	top: 50%;
	height: auto;
	transform: translate(-50%,-50%);
	height: -webkit-fill-available;
}

.container__svg__info.active span {
	background: var(--main-hg-color);
	height: 38px;
	width: 38px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

.container__title__day__info {
	display: flex;
	justify-content: space-between;
}

.container__svg__info.active span svg {
	position: absolute;
	left: 50%;
	top: 50%;
	height: auto;
	transform: translate(-50%,-50%);
}

.container__svg__info.active {
	background: transparent;
	border: 0.5px solid var(--main-hg-color);;
}

.container__svg__info.active svg {
	fill: black;
}


.container__lists__info #informationsText {
	font-weight: 300;
	font-size: 16px;

}

.container__content__info p{
	margin: 0;
}

.container__content__info {
	width: calc(100% - 54px);
}

#informationsEvent {
	text-transform: uppercase;
	font-weight: bold;
}
#informationsEventTime {
	color: #BBBBBB;
	font-weight: 500;
	font-size: 13px;
}

#informationsText {

}

#informationsIconNew {
	background-color: var(--main-hg-color);
}

#informationsIcon {
	
}

.container__svg__info div {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
	width: 100%;
	z-index: 9999;
	background: var(--main-hg-color);
	-webkit-mask-repeat: no-repeat;
	-webkit-box-align : center;
	-webkit-mask-position-y: center;
	-webkit-mask-position-x: center;
}

.container__svg__info.active div {
	background: #ffffff;
}

.container__info .active .footerPicto {
	background-color: var(--footer-selected-color);
}
.container__info .welcome {
	-webkit-mask-image: url("../res/eventWelcome.svg");
	mask-image: url("../res/eventWelcome.svg");

}
.container__info .support {
	-webkit-mask-image: url("../res/eventSupport.svg");
	mask-image: url("../res/eventSupport.svg");
}
.container__info .reminder {
	-webkit-mask-image: url("../res/eventReminder.svg");
	mask-image: url("../res/eventReminder.svg");
}
.container__info .event {
	-webkit-mask-image: url("../res/eventEvent.svg");
	mask-image: url("../res/eventEvent.svg");
}

