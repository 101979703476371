:root {
  --main-hg-color: #FF9E17;
  --main-txt-secondary: #C5C5C5;
  --main-txt-primary: #4D4B48;
  --main-link-color: #BBB;
  --main-link-color-hover: #AAA;
  --footer-selected-color: #3C3C3B;
  --footer-separator-color: #DEDEDE;
  --background-svg: #F6F4F1;

  --main-width: 370px;
  --inputPadding: 1em;
  --buttonPadding: 1em;
}

body {
  font-family: Clother;
  font-weight: bold;
}

.button {
  letter-spacing: 1.2px;
}
#headerLogo {
  background-image: url("../../public/pictures/Logo_Pequignet.png");
}

#footerWallet .footerPicto {
  -webkit-mask-image: url("../res/logo.svg");
  mask-image: url("../res/logo.svg");
}
#footerStore .footerPicto {
  -webkit-mask-image: url("../res/footerStoreSelected.svg");
  mask-image: url("../res/footerStoreSelected.svg");
}
#footerInfo .footerPicto {
  -webkit-mask-image: url("../res/footerInfosSelected.svg");
  mask-image: url("../res/footerInfosSelected.svg");
}
#footerProfile .footerPicto {
  -webkit-mask-image: url("../res/footerProfileSelected.svg");
  mask-image: url("../res/footerProfileSelected.svg");
}
#connectPicture {
  background-image: url("../../public/pictures/connect.png");
  width: calc(100% + 10px);
  height: calc((100vw - 40px) / 3 * 2);
  margin-left: -5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: -12px 22px 48px 34px rgb(90 90 90 / 15%);
  margin-bottom: 30px;
}

@media only screen and (min-width: 800px) {
  #connectPicture {
    height: 300px;
  }
}
