#updateProfileFirstnameLabel {

}

#updateProfileFirstname {

}

#updateProfileLastnameLabel {

}

#updateProfileLastname {

}

#updateProfilePasswordLabel {

}

#updateProfilePassword {

}

#updateProfileConfirmationLabel {

}

#updateProfileConfirmation {

}

#updateProfileBack {

}

.toto {
    background: beige;
    width: 100%;
    height: 100%;
}

#updateProfileButton {

}