#storeTitle {
  font-family: Silvana;
  font-weight: 300;
  font-size: 60px;
  color: var(--main-link-color);
  margin-top: 0px;
  margin-bottom: 23px;
  line-height: 1em;
}

#header {
  font-weight: normal;
}

#storeText {
	font-size: 16px;
	color: var(--footer-selected-color);
	font-weight: 500;
	margin-bottom: 40px;
}

#storeButton {
	display: block;
	margin-top: 86px;
	margin-bottom: 30px;
}

.storeImage {
	display: flex;
  justify-content: space-between;
	width: 100vw;
	margin-left: -25px;
	background-color: var(--background-color);
  padding-bottom: 30px;
}

#storeImg1 {
	background-image: url('../../public/pictures/store_01.png');
  margin-left: -10%;
}

#storeImg2 {
	background-image: url('../../public/pictures/store_02.png');
}

#storeImg3 {
	background-image: url('../../public/pictures/store_03.png');
  margin-right: -10%;
}
.storeImageDetail {
  width: 33.3%;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 30vh;
}
