

.uploadComponent {
    outline: 2px dashed rgb(255, 158, 23);
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .4s linear;
    transition: outline-offset .15s ease-in-out, background-color .4s linear;
    font-size: 1.25rem;
    background-color: white;
    position: relative;
    width: 100%;
    font-family: OpenSans;
    font-weight: 300;
    color: var(--main-link-color);
}

.uploadComponent:hover {
    background-color: rgba(255, 158, 23, 0.5);
}

.uploadComponent strong {
    color: #000;
}

.uploadComponent input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    padding: 0px;
}

.uploadComponent label {
    width: 100%;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 10px;
}
