.collapseBtn {
    border: none;
    background-color: white;
    width: 100%;
    text-align: start;
}

input[type="date"] {
    border: solid 1px var(--main-hg-color)!important;
    padding: var(--inputPadding);
    border-radius: 0;
}

input[type="date"]:focus {
    border: solid 2px black!important;
    border-radius: 4px;
    box-shadow: none;
}