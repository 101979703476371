.titleHeadWallet {
  margin-top: 25px;
}
.walletHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 23px;
}

#walletTitle {
  font-family: Silvana;
  font-weight: 300;
  font-size: 60px;
  color: var(--main-link-color);
  margin-top: 0;
  margin-bottom: 0;
  line-height: 60px;
}

#walletAddProduct a {
  color: initial;
  display: flex;
  align-items: center;
  font-size: 9px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: var(--footer-selected-color);
}

#walletAddProduct a svg {
  width: 44px;
  height: 44px;
  margin-left: 12px;
}

#walletProductBlock,
.walletProductBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: var(--background-color);
  height: 28vh;
  color: var(--footer-selected-color);
}

#walletProductName,
.walletProductName {
	font-weight: bold;
  font-size: 25px;
  text-transform: uppercase;
  padding-top: 15px;
  text-align: center;
}

#walletProductModel,
.walletProductModel {
	font-family: Silvana;
  font-weight: 300;
  text-align: center;
  margin-top: 5px;
}

#walletProductPicture,
.walletProductPicture {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-grow: 1;
}

/*//////////*/
.productCardContent{
  display: flex;
  flex-direction: column;
  height: 28vh;
  color: #3c3c3b;
  align-items: center;
  justify-content: space-around;
  background-color: #eee;

}
.productCardPicture{
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-grow: 1;
  width: 100%;
}

.productCardContent{
  font-family: OpenSans;
}

.productCardTitle {
  text-align: center;
  border-bottom: rgb(255, 158, 23) solid 1px;
}

.productCard {
  cursor: pointer;
  position: relative;
  background-color: #eee;
  transition: transform 0.5s;
}

.productCard::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  content: "";
  opacity: 0;
  z-index: -1;
}
.productCard:hover::after, .productCardContent:focus::after {
  opacity: 1;
}

.tabBtn {
  background-color: white;
  width: 100%;
  border: solid 1px var(--main-hg-color);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px;
  padding: var(--buttonPadding);
  cursor: pointer;
  display: block;
  transition: background-color 0.5s ease-in-out;
}

.tabBtn:disabled {
  opacity: 0.5;
}

.tabBtnSelected {
  background-color: var(--main-hg-color);
}

.invoiceMissing {
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: lighter;
  border: 1px solid black;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding: 10px;
  font-style: italic ;
}

.invoiceMissing p{
  margin: 0;
}

.productCardHeader {
  justify-content: flex-end !important;
}

.invoiceVerif {
  color: red;
  border: 1px solid red;
  border-radius: 25px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding: 10px;
  background-color: rgba(252,0,0,0.1);
}

.invoiceVerif p{
  margin: 0;
}


.container__switch {
  width: 192px;
  height: 42px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #121212;
  border-radius: 10px;
  opacity: 0.1;
  display: flex;
}

.container__switch__left {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  left: 241px;
  width: 53px;
  height: 19px;
  width: 50%;
  font-family: 'Plus Jakarta Sans';
}

.container__switch__right {
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  left: 241px;
  width: 53px;
  height: 19px;
  width: 50%;
  font-family: 'Plus Jakarta Sans';
}

.container__switcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.container__switcher span.switcher {
  position: relative;
  width: 192px;
  height: 42px;
  border-radius: 10px;
  margin: 40px 0
}

.container__switcher span.switcher input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  width: 192px;
  height: 42px;
  border-radius: 10px;
  background-color: transparent;
  outline: 0;
  font-family: Poppins;
  cursor: pointer;
  border: 1px solid rgba(18,18,18,0.1);
  border-radius: 10px;
  display: flex;
}

@media (max-width: 600px) {
  .container__switcher span.switcher input {
    width:100%
  }
}

.container__switcher span.switcher input:after,.container__switcher span.switcher input:before {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff
}

.container__switcher span.switcher input:before {
  content: var(--jour-actuel);
  left: 20px;
  font-family: 'Plus Jakarta Sans';
  font-size: 15px;
  width: 100%;
  font-weight: 700;
  height: 100%;
  left: 0;
  padding: 8px;
  padding-left: 21px;
}

.container__switcher span.switcher input:after {
  content: var(--jour-suivant);
  right: 20px;
  font-family: 'Plus Jakarta Sans';
  font-size: 15px;
  letter-spacing: 0;
  font-weight: 700;
}

.container__switcher span.switcher label {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: -3px;
  border-radius: 8px
}

.container__switcher span.switcher.switcher-1 input {
  transition: .25s -.1s
}

.container__switcher span.switcher.switcher-1 input:checked {
  background-color: #fff
}

.container__switcher span.switcher.switcher-1 input:checked:before {
  color: #fff;
  transition: color .5s .2s
}

.container__switcher span.switcher.switcher-1 input:checked:after {
  color: #000000;
  transition: color .5s
}

.container__switcher span.switcher.switcher-1 input:checked+label {
  left: 5px;
  top: 3px;
  right: 98px;
  height: 36px;
  background-color: var(--main-hg-color);
  transition: left .5s,right .4s .2s;
  box-shadow: 0 4px 10px rgba(0,0,0,.1)
}

@media (max-width: 300px) {
  .container__switcher span.switcher.switcher-1 input:checked+label {
    right:100px
  }
}

.container__switcher span.switcher.switcher-1 input:not(:checked) {
  transition: background .5s -.1s
}

.container__switcher span.switcher.switcher-1 input:not(:checked):before {
  background-color: var(--main-hg-color);
  transition: color .5s
}

.container__switcher span.switcher.switcher-1 input:not(:checked):after {
  color: #fff;
  transition: color .5s .2s
}

.container__switcher span.switcher.switcher-1 input:not(:checked)+label {
  left: 102px;
  top: 3px;
  height: 36px;
  right: 5px;
  background-color: var(--main-hg-color);
  transition: left .4s .2s,right .5s,background .35s -.1s;
  box-shadow: 0 4px 10px rgba(0,0,0,.1)
}

@media (max-width: 600px) {
  .container__switcher span.switcher.switcher-1 input:not(:checked)+label {
    left:50% !important;
  }

  .container__switcher span.switcher.switcher-1 input:checked+label {
    right: 50%;
  }
}

@media (max-width: 300px) {
  .container__switcher span.switcher.switcher-1 input:not(:checked) + label {
    left:100px
  }
}

.container__toggle__switch {
  height: fit-content;
  margin-top: 20px;
  margin-bottom: 20px;
}
.container__toggle__switch button {
  height: fit-content;
  margin-top: 0;
  padding: 6px 5px;
}

.container__toggle__switch button:first-child {
  border-radius: 10px 0px 0 10px;
}

.container__toggle__switch button:last-child {
  border-radius: 0 10px 10px 0;
}

.togglebutton {
  padding-top: 28px;
}

.deleteBanner {
  border: solid 1px rgb(255, 158, 23);
  border-radius: 10px;
  padding: 5px;
  background-color: rgba(255, 158, 23, 0.3);
  color: black;
  /*width: 30%;*/
  display: flex;
  justify-content: space-between;
}

.deleteCancel {
  background-color: transparent;
  border: solid 1px rgb(255, 158, 23);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease
}

.deleteCancel:hover {
  background-color: rgba(255, 158, 23, 0.3);
}

.containerLanguage {
  max-width: calc(100vw - 50px);
  margin: 0 auto;
  z-index: 11;
}
.selectLanguage {
  margin-top: 5px;
  width: 5%;
}

@media (max-width: 1200px) {
  .selectLanguage {
    width: 10%;
  }
}

@media (max-width: 600px) {
  .selectLanguage {
    width: 20%;
  }
}

.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:focus,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown .menu
{
  border-color: rgb(255, 158, 23)!important;
  box-shadow: 0 2px 3px 0 rgba(255, 158, 23,.15)!important;
}

.walletProducts:last-child {
    margin-bottom: 50px;
}
