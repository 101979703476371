:root {
  --main-hg-color: #FF9E17;
  --main-txt-secondary: #C5C5C5;
  --main-txt-primary: #4D4B48;
  --main-link-color: #BBB;
  --main-link-color-hover: #AAA;
  --footer-selected-color: #3C3C3B;
  --footer-separator-color: #DEDEDE;
  --background-color: #EEE;
  --main-width: 370px;
  --inputPadding: 1em;
  --buttonPadding: 1em;
}

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  /*width: var(--main-width);*/
  line-height: 1.35em;
  color: var(--main-txt-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  /*width: 100%;*/
  /*padding: 0 25px;*/
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}
#root > div:not(#footer) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.footerHeight {
  height: calc(100% - 90px);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#headerLogo {
  height: 19.03px;
  width: calc(100vw / 3);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

#footer {
  position: fixed;
  z-index: 999;
  bottom: 0px;
  width: var(--main-width);
  left: 0;
  width: 100vw;
  font-size: 8px;
  background: #fff;
  height: 79px;
}
#footer .footerTitle {
  margin-top: 5px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
#footer .footerPicto {
  background-color: var(--main-link-color);
  height: 22px;
  width: 22px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  mask-size: 100%;
  -webkit-mask-size: 100%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}
#footer .selected .footerPicto {
  background-color: var(--footer-selected-color);
}

#footer .selected .footerTitle {
  color: var(--footer-selected-color);
}

#footer .footerItemContainer {
  width:100%;
}
#footer .footerItemContainer > div  {
  position: relative;
}
#footerWallet .footerHome {
  background-image: url("../res/logo.svg");
}

#footerStore {
  
}

#footerInfo {
  
}

#footerNewCount {
  position: absolute;
  background-color: var(--main-hg-color);
  border-radius: 100%;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translateX(calc(-50% + 7px));
  top: 17px;
  border: solid 1px #fff;
}

#footerProfile {
  
}

#header {
  /*margin-bottom: 30px;*/
}

#header .absoluteTitle {
  font-family: Belluga;
  font-size: 55px;
  font-weight: 400;
  color: var(--main-hg-color);
}

#content {
  text-align: left;
  z-index: 5;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 80vh;
  padding-left: 25px;
  padding-right: 25px;
  overflow-x: hidden;
}
#content:after {
  content: var(--version);
  color: #ddd;
  font-size: 10px;
  font-weight: 400;
  text-align: right;
}

.footerHeight #content {
  max-height: 67vh;
}

.button {
  background-color: var(--main-hg-color) !important;
  width: 100%;
  border: none;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px;
  padding: var(--buttonPadding);
  cursor: pointer;
  display: block;
}

.button:disabled {
  opacity: 0.5;
}

.button svg {
  margin-left: 5px;
}

.chevronBack {
  margin-right: 10px;
}

a {
  text-decoration: none;
}

a:not(.button) {
  color: var(--main-link-color);
}

a.button {
  color: inherit;
  text-align: center;
}

a:hover {
  color: var(--main-link-color-hover);
}

#footer:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--footer-separator-color);
  left: 0;
  top: 0;
}

#footer .footerItemContainer {
  display: flex;
  justify-content: space-evenly;
}

#footer .footerItemContainer > div {
  padding: 20px 15px 0;
}

#footer .footerItemContainer .selected {
  position: relative;
}
#footer .footerItemContainer .selected{
  background-color : unset;
}
#footer .footerItemContainer .selected:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--main-hg-color);
  left: 0;
  bottom: 0;
}


body #footer .tiret {
  content: '';
  position: absolute;
  width: 100%;
  padding: 0;
  height: 1px;
  background-color: var(--main-hg-color);
  left: 0;
  top: 0;
}

form {
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

form > *:not(:last-child) {
  margin-bottom: 10px;
}

input {
  width: 100%;
  border: solid 1px var(--main-hg-color);
  padding: var(--inputPadding);
  font-weight: bold;
}

select {
  width: 100%;
  border: solid 1px var(--main-hg-color);
  padding: var(--inputPadding);
  font-weight: bold;
}

.textPR {
  padding-right: 80px;
}

.backLink {
  letter-spacing: 1px;
  position: absolute;
  top: 25px !important;
  z-index: 9999;
  font-weight: bold;
  color: var(--footer-selected-color) !important;
  text-transform: uppercase;
  font-size: 10px;
  left: 25px;
}

body form .labelInput {
  color: #BBBBBB;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.buttonFormLast {
  font-size: 12px;
  width: 100%;
  text-align: center;
  color: #BBBBBB;
  font-weight: bold;
  display: block;
  margin-top: 13px;
}

body #content .btnReverse {
   min-height: 40px;
   color: var(--footer-selected-color);
   font-size: 12px;
   letter-spacing: 1.2px;
   font-weight: bold;
   display: flex;
   justify-content: center;
   width: 100%;
   padding: 0;
   font-family: 'Clother';
   align-items: center;
}

.buttonsubmit {
  font-family: 'Clother';
  background: rgb(255, 158, 23) !important;
  color: white !important;
}

body #content .buttonsubmit svg {
  margin-top: -4px;
}

.btnBo{
  background: rgb(255, 158, 23);
  color: white;
  width: 200px;
}
.btnBo:hover{
  color: white;
}

.buttonsubmit:hover{
  color: white;
}
.titleHead {
  font-family: inherit;
  font-weight: 300;
  color: var(--main-link-color);
  padding-top: 70px;
  /*position: fixed;*/
  top: 0;
  z-index: 10;
  right: 0;
  width: 100vw;
  animation-duration: 0.30s;
  animation-name: slideinHead;
  background: white;
  max-width: calc(100vw - 50px);
  margin: 0 auto;

}

.walletPagereverseAnim {
  left: 25px !important;
}

@keyframes slideinHead {
  from {
    width: 0;
  }

  to {
    width: 100vw;
  }
}


body .Wallet {
  padding-top: 23px;
}

.titleHead:after {
  content: '';
  width: 100vw;
  left: 0;
  bottom: 0;
  height: 30px;
  background: linear-gradient(to bottom,rgba(255,255,255,0.1),rgba(255,255,255,0));
  position: absolute;
}


.container__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: -webkit-fill-available;
  /*margin: auto;*/
}

.container__list li {
  list-style: none;
  margin: inherit;
  padding: 21px 0;
  justify-content: space-between;
  display: flex;
  border-bottom: 0.5px solid var(--footer-separator-color);
  align-items: center;
  min-height: 64.59px;
  position: relative;
}

.container__list li:last-child {
  border: initial;
  margin-bottom: 29px;
}

.container__list li a {
  color: var(--footer-selected-color);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.65px;
  display: flex;
  align-items: center;
  top: 0;
  right: -25px;
  bottom: 0;
  left: -25px;
  width: 100%;
}

.productHeader {
  padding-top: 0 !important;
}


body {
  overflow-y: hidden;
}

.btnReverse {
  background:white !important;
  color: rgb(255, 158, 23) !important;
  border: 1px solid rgb(255, 158, 23);
}
