#loginTitle {

}


#loginEmail {
	
}

#loginPassword {
	
}

#loginPasswordContainer {
    position: relative;
}

#loginForgotten {
	position: absolute;
  right: var(--inputPadding);
  top: 50%;
  transform: translateY(-50%);
}

#loginButton {
	background-color: var(--main-hg-color);
}

.loginLink {
	text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.container__loginpage:not(.container__information__register__product) #headerLogo {
    margin-bottom: 50px;
}