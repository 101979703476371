.message {
    position:fixed;
    top:0px;
    right:15px;
    z-index:40;
    border: solid 1px #aaaaaa;
    border-radius: 5px;
    font-weight: bold;  
    box-shadow: 2px 2px 0px #999999;
}

.message.error {
    background-color: red;
    color: yellow;
}

.message.info {
    background-color: #00558a;
    color: #fff;
}