#productHistoryBack {
	text-transform: uppercase;
	font-size: 10px;
}

#productHistoryBack {

	position: absolute;
	top: 15px;
	font-weight: bold;
	color: var(--footer-selected-color);
	text-transform: uppercase;
	font-size: 10px;
}
#productHistoryBack svg {
	margin-right: 9px;

}



#productHistoryTitle {
	font-size: 60px;
	font-family: Silvana;
	font-weight: 300;
	font-size: 60px;
	color: var(--main-link-color);
	margin-top: 0px;
	margin-bottom: 40px;
	line-height: 1em;
}

.container__list__history:after {
	content: '';
	width: 0.5px;
	background: var(--footer-separator-color);
	height: 79vh;
	position: absolute;
	top: 7px;
	left: 5px;
	z-index: -1;
}
#productHistoryEntry:last-child #productHistoryTag:after {
	content: none;
	width: 0.5px;
	background: var(--footer-separator-color);
	height: 92px;
	position: absolute;
	top: 0;
	left: 4.5px;
	z-index: -1;
}

.container__list__history {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

#productHistoryEntry {
	display: flex;
	align-items: flex-start;
	margin-bottom: 40px;
}

.svgProductHistory {
	margin-top: 5px;
}

#productHistoryTag {
	display: block;
	border-radius: 5px;
	width: 10px;
	height: 10px;
	margin-right: 18px;
	background-color: var(--main-hg-color);
	position: relative;
}

#productHistoryDate {
	color: var(--main-txt-secondary);
	display: block;
	font-size: 12px;
}

#productHistoryText {
	display: block;
	font-size: 17px;
	font-weight: 500;
}