#productServiceBack {

    position: absolute;
    top: 15px;
    font-weight: bold;
    color: var(--footer-selected-color);
    text-transform: uppercase;
    font-size: 10px;
}
#productServiceBack svg {
    margin-right: 9px;
}

#productServiceTitle {
    font-family: Silvana;
    font-weight: 300;
    font-size: 60px;
    color: var(--main-link-color);
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 1em;
}

.container__link__service {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container__link__service li {
    list-style: none;
    margin: 0;
    padding: 21px 0;
    justify-content: space-between;
    display: flex;
    border-bottom: 0.5px solid var(--footer-separator-color);
    align-items: center;
    min-height: 64.59px;
    position: relative;
}

.container__link__service li:last-child {
    border: initial;
    margin-bottom: 29px;
}

.container__link__service li a {
    color: var(--footer-selected-color);
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.65px;
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.imageServiceFooter {
    width: 100vw;
  margin-bottom: -6px;
}

.container__img__montre {
    position: relative;
    width: 100%;
    margin-left: -25px;
    margin-right: -25px;
    z-index: -1;
}
