#productDescriptionGenericBlock,
#productDescriptionOptionsBlock {
	padding-bottom: 30px;
	border-bottom: 0.5px solid var(--footer-separator-color);
	position: relative;
	margin-right: 33%;
}

#productDescriptionGenericTitle {
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}

#productDescriptionBack {

	position: absolute;
	top: 15px;
	font-weight: bold;
	color: var(--footer-selected-color);
	text-transform: uppercase;
	font-size: 10px;
}
#productDescriptionBack svg {
	margin-right: 9px;
}


#productDescriptionOptionsBlock {
	margin-top: 25px;
}

#productDescriptionTechnicBlock{
	margin-top: 25px;
	margin-bottom: 25px;
}
#productDescriptionTechnicTitle,
#productDescriptionOptionsTitle{
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 15px;
	display: block;
	text-transform: uppercase;
	color: var(--footer-selected-color);
}


#productDescriptionBack {
	text-transform: uppercase;
	font-size: 10px;
}

#productDescriptionTitle {
	font-family: Silvana;
	font-weight: 300;
	font-size: 60px;
	color: var(--main-link-color);
	margin-top: 0px;
	margin-bottom: 23px;
	line-height: 1em;
}

#productDescriptionGenericTitle {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 15px;
	display: block;
	text-transform: uppercase;
	color: var(--footer-selected-color);
}

#productDescriptionGenericKey {
	color: var(--main-txt-secondary);
	font-size: 12px;
	line-height: 17px;
	display: block;
}

#productDescriptionGenericValue {
	font-size: 17px;
	color: var(--footer-selected-color);
	line-height: 23px;
	display: block;
	margin-bottom: 15px;
	font-weight: 500;
}

#productDescriptionTechnicKey {
	color: var(--main-txt-secondary);
	font-size: 12px;
	line-height: 17px;
	display: block;
}

#productDescriptionTechnicValue {
	font-size: 17px;
	color: var(--footer-selected-color);
	line-height: 23px;
	display: block;
	margin-bottom: 15px;
	font-weight: 500;
}

.productDescriptionGenericBlockImage {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
  right: -130%;
  width: 87vw;
	height: 100%; 
}
