#header.header__profil {
  margin-bottom: 57px;
}
#profileTitle {
  margin: 0;
  font-family: Silvana;
  font-weight: 300;
  font-size: 60px;
  color: var(--main-link-color);
  line-height: 1em;
  width: 100%;
  margin-bottom: 86px;
}

#profileIcon {

}



#profileName {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin: 0;
  color: var(--footer-selected-color);
}

#profileEmail {
  font-weight: 300;
  text-align: center;
  width: 100%;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  color: var(--footer-selected-color);
}

.container__title__img__profil {
  position: relative;
  margin-top: 30px;
  justify-content: center;
  display: flex;
}

#profileIcon {
  width: 99px;
  height: 99px;
  border-radius: 50%;
  border: 0.5px solid var(--main-hg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background: #ffffff;
}

#profileIcon img{
  width: 89px;
  height: 89px;
  border-radius: 50%;
}

#profileMenuIcon {
  margin-right: 18px;
  width: 21px;
  height: 21px;
}

#profileMenuChevron {
  margin-left: 10%;
  right: 0;
  position: absolute;
}
.container__link__profile {
  margin-top: 20px;
}
#profileLogout {
  height: 40px;
  color: var(--footer-selected-color);
  font-size: 12px;
  font-family: 'Clother';
  font-weight: bold;
}

.profileHeader {
  align-items: start;
}
