.container__entretien {
    margin: 0;
    padding: 0;
}

#productentretienEntry {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: block;
    position: relative;
    display: flex;
}

#entretienTitle {
    font-family: Silvana;
    font-weight: 300;
    font-size: 60px;
    color: var(--main-link-color);
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 1em;
}

#productentretienEntry #productEntretienTag {
    display: block;
    border-radius: 5px;
    width: 10px;
    height: 10px;
    margin-right: 18px;
    background-color: var(--main-hg-color);
    position: relative;
    margin-top: 17px;
}

#productentretienEntry:after {
    content: "";
    width: 100vw;
    background: #DEDEDE;
    left: -25px;
    bottom: 0;
    height: 0.5px;
    position: absolute;
}

#productentretienEntry:last-child:after {
    content: inherit;
    height: 0;
}

.entretienContainerDay {
    display: flex;
}

.entretienContainerDay span {
    margin-right: 8px;
}
.mapEntretien {
    max-width: 100%;
    object-fit: cover;
}