#subscribeTitle {
	color: var(--main-hg-color);
}

#subscribeIntro {
	margin-bottom: 30px;
}

#subscribeFirstname {
	
}

#subscribeLastname {
	
}

#subscribeEmail {
	
}

#subscribePassword {
	
}

#subscribeButton {
	background-color: var(--main-hg-color);
}

#subscribeLogin {
	margin-left: auto;
  margin-right: auto;
}