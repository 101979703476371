.header__product:before {
  content: '';
  background: #EEE;
  height: 63%;
  width: 100vw;
  position: absolute;
  z-index: -1;
  animation-duration: 0.30s;
  animation-name: slidein;
}


/*@keyframes slidein {*/
/*  from {*/
/*    left: 0px;*/
/*    width: 100vw;*/
/*  }*/

/*  to {*/
/*    left: -25px;*/
/*    width: 100vw;*/
/*  }*/
/*}*/

@keyframes slideinafter {
  from {
      left: 25px;
      width: calc(100vw - 50px);
  }

  to {
    left: 0px;
    width: 100vw;
  }
}


.header__product {
  position: relative;
  margin-bottom: 0;
}

#productMenuIcon {
  margin-right: 18px;
  width: 21px;
  height: 21px;
}

#productMenuChevron {
  margin-left: 10%;
  right: 0;
  position: absolute;
}

#productTitleBack {
  position: absolute;
  top: 15px;
  font-weight: bold;
  color: var(--footer-selected-color);
  text-transform: uppercase;
  font-size: 10px;
}

#productTitleBack svg {
  margin-right: 9px;
}

#productTitle {
  margin: 0;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: var(--footer-selected-color);
  font-size: 30px;
  line-height: 50px;
  padding-top: 50px;
  position: relative;
}

#productTitle svg, #productpseudo svg {
  position: absolute;
  top: 20px;
  right: 25px;
  width: 35px;
  height: 35px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid var(--main-hg-color);
}

#productpseudo {
  display: none;
  padding-top: 70px;
  position: relative;
  margin-bottom: 27px;
}

.activePseudo #productTitle {
  display: none;
}

.activePseudo #productpseudo {
  display: block;
}

#productName {
  margin: 0;
  text-transform: uppercase;
  font-size: 15px;
  width: 100%;
  text-align: center;
  color: var(--footer-selected-color);
  font-weight: 300;
  font-family: Silvana;
  text-transform: revert;
  line-height: 12px;
  margin-bottom: 19px;
}

#productPicture {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  height: 30vh;
  max-height: 327px;
  margin-bottom: 12px;
}

.container__link__product {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container__link__product li {
  list-style: none;
  margin: 0;
  padding: 21px 0;
  justify-content: space-between;
  display: flex;
  border-bottom: 0.5px solid var(--footer-separator-color);
  align-items: center;
  min-height: 64.59px;
  position: relative;
}



.container__link__product li:last-child {
  border: initial;
  margin-bottom: 20px;
}

.container__link__product li a {
  color: var(--footer-selected-color);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.65px;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inputChangePseudo {
  width: calc(100vw - 50px);
}

.disabledLink {
  pointer-events: none;
  stroke: darkgrey!important;
 }

.disabledLink a {
  color: darkgrey!important;
}

.disabledLink path{
  stroke: darkgrey!important;
}

#contentProduct {
  text-align: left;
  z-index: 5;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 80vh;
  padding-left: 25px;
  padding-right: 25px;
  overflow-x: hidden!important;
}

@media only screen and (min-width: 950px) {
  .container__link__product li {
    max-width: 300px;
    justify-content: center;
    margin: 0 auto;
  }
  #contentProduct {
    /*position: absolute;*/
    /*top: 50vh;*/
    width: -webkit-fill-available;
    padding-bottom: 0;
  }

  #productPicture {
    height: 40vh;
  }
  #productTitle {
    font-size: 35px;
    line-height: 50px;
    padding-top: 50px;
  }
}