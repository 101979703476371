
#registerIntro {
	font-weight: 500;
	font-size: 16px;
	color: var(--footer-selected-color);
	margin-bottom: 30px;
	display: block;
}

#registerFirstname {
	
}

#registerLastname {
	
}

#registerEmail {
	
}

#registerPassword {
	
}

#registerButton {
	background-color: var(--main-hg-color);
}

#registerLogin {
	margin-left: auto;
	margin-right: auto;
	color: var(--main-link-color);
}

#registerProduct {
	color: var(--footer-selected-color);
	font-size: 35px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
	line-height: 27px;
}

.header__register__product {
	position: relative;
	min-height: 253px;
}
.container__information__register__product {
	flex-direction: column;
	display: flex;
	justify-content: center;
	width: 100%;
	top: -18px;
}
.container__information__register__product svg {
  margin: 7px auto -15px;
	display: block;
}