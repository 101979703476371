#productTokenBack {
	position: absolute;
	top: 15px;
	font-weight: bold;
	color: var(--footer-selected-color);
	text-transform: uppercase;
	font-size: 10px;
}
#productTokenBack svg {
	margin-right: 9px;
}


#productTokenTitle {
	margin-top: 0;
	font-family: Silvana;
	font-weight: 300;
	font-size: 60px;
	color: var(--main-link-color);
	line-height: 1em;
	margin-top: 0;
	margin-bottom: 24px;
}

#productTokenText {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 25px;
}

#productTokenKey {
	color: var(--main-txt-secondary);
	font-size: 12px;
	line-height: 17px;
	display: block;
}

#productTokenValue {
	font-size: 17px;
	line-height: 23px;
	display: block;
	font-weight: 500;
	margin-bottom: 15px;
	text-transform: uppercase;
	color: var(--footer-selected-color);
}

#productTokenPicture {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	display: block;
	margin: 25px 0;
	padding: 25px 0;
	display: flex;
	justify-content: center;
	border-top: 0.5px solid #BBBBBB;
	border-bottom: 0.5px solid #BBBBBB;
}

#productTokenPicture img {
	max-height: 213px;
	height: 213px;
	margin: 0 auto;
}