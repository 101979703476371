#supportsTitle {
    font-family: Silvana;
    font-weight: 300;
    font-size: 60px;
    color: var(--main-link-color);
    margin-top: 0px;
    margin-bottom: 40px;
    line-height: 1em;
}

.container__lists__supp {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
}
.container__supp {
    padding-top: 20px;
    list-style: none;
    display: flex;
    padding-bottom: 20px;
    border-bottom: 0.5px solid var(--footer-separator-color);
}



.container__svg__supp {
    height: 44px;
    width: 44px;
    margin-right: 12px;
    background: var(--background-svg);
    border-radius: 50%;
    position: relative;
}

.container__svg__supp span svg {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%,-50%);
    height: -webkit-fill-available;
}

.container__svg__supp.active span {
    background: var(--main-hg-color);
    height: 38px;
    width: 38px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.container__title__day__supp {
    display: flex;
    justify-content: space-between;
}

.container__svg__supp.active span svg {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%,-50%);
}

.container__svg__supp.active {
    background: transparent;
    border: 0.5px solid var(--main-hg-color);;
}

.container__svg__supp.active svg {
    fill: black;
}


.container__lists__supp #supportsText {
    font-weight: 300;
    font-size: 16px;
}

.container__content__supp p{
    margin: 0;
}

.container__content__supp {
    width: calc(100% - 54px);
}

#supportsEvent {
    text-transform: uppercase;
    font-weight: bold;
}
#supportsEventTime {
    color: #BBBBBB;
    font-weight: 500;
    font-size: 13px;
}

#supportsText {

}

#supportsIconNew {
    background-color: var(--main-hg-color);
}

#supportsIcon {

}