#newBack {

}

#newBack {

}

#newIntro {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 42px;
    max-width: 270px;
    display: block;
}

#newButtonScan {
    font-size: 12px;
    margin-top: 60px;

}

.qrcodesvg {
    position: relative;
}

.qrcodesvg svg{
    margin: 0 auto;
    width: 100%;
}

.qrcodesvg video {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

#newBack {
    position: absolute;
    top: 15px;
    font-weight: bold;
    color: var(--footer-selected-color);
    text-transform: uppercase;
    font-size: 10px;
}

#newBack svg {
    margin-right: 9px;
}

#newTitle {
    margin-top: 0;
    font-family: Silvana;
    font-weight: 300;
    font-size: 60px;
    color: var(--main-link-color);
    line-height: 1em;
    margin-bottom: 24px;
}

#newManual {

}

#newButtonNew {
    font-size: 12px;
}

#newSerialNumber {

}

.paddingBottom {
    margin-bottom: 24px;
}

.formNew {
    max-width: 80%;
    justify-content: center;
    margin: 0 auto;
    width: 75%;
}

.parent-container {
    overflow-y: auto;
    max-height: 100%;
}


.btnWallet{
    background: rgb(255, 158, 23);
    color: white;
}

.btnWallet:hover{
    color: white;

}

.btnWalletReverse{
    border: 1px solid rgb(255, 158, 23);
    background: white;
    color: rgb(255, 158, 23);
}

.btnWalletReverse:hover{
    color: rgb(255, 158, 23);

}
.btn:focus {
    box-shadow: none!important;
}

.showError {
    border: 1px solid rgb(255, 158, 23);
    border-radius: 5px;
    background-color: rgba(255, 158, 23, 0.3);
    padding: 5px;
    width: max-content;
}

.newLanguage {
    position: absolute;
    top: 15px;
    right:25px
}

.topQrModal {
    margin-bottom: 5px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
}

.flash {
    background-color: white;
    border: black solid 2px;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    color: black;
    font-size: 25px;
}

.flashOn {
    border-color: rgb(255, 158, 23);
    color: rgb(255, 158, 23);
}